import "core-js/modules/es6.object.keys";
import "core-js/modules/web.dom.iterable";
import "/root/code/valet-admin/node_modules/core-js/modules/es6.array.iterator.js";
import "/root/code/valet-admin/node_modules/core-js/modules/es6.promise.js";
import "/root/code/valet-admin/node_modules/core-js/modules/es6.object.assign.js";
import "/root/code/valet-admin/node_modules/core-js/modules/es7.promise.finally.js";
import Vue from 'vue';
import Cookies from 'js-cookie';
import 'normalize.css/normalize.css'; // a modern alternative to CSS resets

import Element from 'element-ui';
import "./styles/element-variables.scss";
import '@/styles/index.scss'; // global css

import VueVideoPlayer from 'vue-video-player';
import 'video.js/dist/video-js.css'; // import 'video.js/dist/lang/zh-CN.js'

Vue.use(VueVideoPlayer);
import App from "./App";
import store from "./store";
import router from "./router";
import _i18n from "./lang"; // internationalization

import "./icons"; // icon

import "./permission"; // permission control

import "./utils/error-log"; // error log

import * as filters from "./filters"; // global filters

import mqtt from "./utils/mqtt";
import aliOss from "./utils/aliOss";
/**
 * If you don't want to use mock-server
 * you want to use MockJs for mock api
 * you can execute: mockXHR()
 *
 * Currently MockJs will be used in the production environment,
 * please remove it before going online! ! !
 */

import { mockXHR } from "../mock";

if (process.env.NODE_ENV === 'production') {
  mockXHR();
}

Vue.use(Element, {
  size: Cookies.get('size') || 'medium',
  // set element-ui default size
  i18n: function i18n(key, value) {
    return _i18n.t(key, value);
  }
}); // register global utility filters

Object.keys(filters).forEach(function (key) {
  Vue.filter(key, filters[key]);
});
Vue.config.productionTip = false; // mqtt

Vue.prototype.$mqtt = mqtt; // ali oss

Vue.prototype.$aliOss = aliOss;
new Vue({
  el: '#app',
  router: router,
  store: store,
  i18n: _i18n,
  render: function render(h) {
    return h(App);
  }
});