/**
 * Created by Jerry.Shi on 2019/7/9.
 */
var mqtt = require('mqtt');

var client = {};
var url = process.env.VUE_APP_WS_API || 'ws://47.111.94.145:8083/mqtt';
export default {
  launch: function launch(id, callback) {
    client = mqtt.connect(url);
    client.on('message', function (topic, message) {
      callback(topic, message);
    });
  },
  end: function end() {
    client.end();
  },
  subscribe: function subscribe(topic) {
    client.subscribe(topic, {
      qos: 1
    });
    console.log('subscribe:', "".concat(url, "-").concat(topic));
  } // publish(topic, message) {
  //   client.publish(topic, JSON.stringify(message), {qos: 1})
  // }

};