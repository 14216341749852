import axios from 'axios';
import { Message } from 'element-ui';
import store from '@/store';
import router from '@/router';
import { getToken } from '@/utils/auth'; // create an axios instance

var service = axios.create({
  baseURL: process.env.VUE_APP_BASE_API,
  // url = base url + request url
  // withCredentials: true, // send cookies when cross-domain requests
  timeout: 20000 // request timeout

}); // request interceptor

service.interceptors.request.use(function (config) {
  // do something before request is sent
  if (store.getters.token) {
    // let each request carry token
    // ['X-Token'] is a custom headers key
    // please modify it according to the actual situation
    config.headers['token-from'] = 'admin';
    config.headers['X-Token'] = getToken();
    config.headers['Authorization'] = getToken();
  }

  return config;
}, function (error) {
  // do something with request error
  console.log(error); // for debug

  return Promise.reject(error);
}); // response interceptor

service.interceptors.response.use(
/**
 * If you want to get http information such as headers or status
 * Please return  response => response
*/

/**
 * Determine the request status by custom code
 * Here is just an example
 * You can also judge the status by HTTP Status Code
 */
function (response) {
  var res = response.data;
  var status = res.code;
  var statusCode = res.StatusCode;

  if (statusCode === '200') {
    return {
      data: res
    };
  }

  if (status === 201) {
    Message({
      message: '创建成功',
      type: 'success'
    });
    return {
      data: res.data
    };
  } else if (status === 204) {
    var message = '删除成功';

    if ('put' === response.config.method) {
      message = '更新成功';
    }

    Message({
      message: message,
      type: 'success'
    });
    return {
      data: res.data
    };
  } else if (status === 400 || status === 401 || status === 409 || status === 500) {
    var _message = res.error_message && res.error_message.message ? res.error_message.message.cn : '未知错误，请重新尝试';

    Message({
      message: _message,
      type: 'error'
    });
    var code = res.error_message ? res.error_message.code : '';

    if ('PERMISSION_NOT_ALLOWED' === code) {
      // 登出系统
      store.dispatch('user/logout').then(function (result) {
        router.push("/login");
      });
    }

    return Promise.reject(new Error(_message || 'Error'));
  } // if the custom code is not 20000, it is judged as an error.


  if (status !== 200 && status !== 201 && status !== 204) {
    Message({
      message: res.message.message || 'Error',
      type: 'error',
      duration: 5 * 1000
    }); // 50008: Illegal token; 50012: Other clients logged in; 50014: Token expired;
    // if (res.code === 50008 || res.code === 50012 || res.code === 50014) {
    //   // to re-login
    //   MessageBox.confirm('You have been logged out, you can cancel to stay on this page, or log in again', 'Confirm logout', {
    //     confirmButtonText: 'Re-Login',
    //     cancelButtonText: 'Cancel',
    //     type: 'warning'
    //   }).then(() => {
    //     store.dispatch('user/resetToken').then(() => {
    //       location.reload()
    //     })
    //   })
    // }

    return Promise.reject(new Error(res.message || 'Error'));
  } else {
    return {
      data: res.data
    };
  }
}, function (error) {
  console.log('err' + error); // for debug

  if (error.response && error.response.data && error.response.data.code) {
    var message = error.response.data.messages.message;
    Message({
      message: message,
      type: 'error',
      duration: 5 * 1000
    });
  }

  return Promise.reject(error);
});
export default service;