import defaultSettings from '@/settings';
import i18n from '@/lang';
var title = defaultSettings.title || 'Vue Element Admin';
export default function getPageTitle(key) {
  var hasKey = i18n.te("route.".concat(key));

  if (hasKey) {
    var pageName = i18n.t("route.".concat(key));
    return "".concat(pageName, " - ").concat(title);
  }

  return "".concat(title);
}