import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.object.keys";
import _objectSpread from "/root/code/valet-admin/node_modules/@babel/runtime-corejs2/helpers/esm/objectSpread2";
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import Cookies from 'js-cookie';
import elementEnLocale from 'element-ui/lib/locale/lang/en'; // element-ui lang

import elementZhLocale from 'element-ui/lib/locale/lang/zh-CN'; // element-ui lang

import elementEsLocale from 'element-ui/lib/locale/lang/es'; // element-ui lang

import elementJaLocale from 'element-ui/lib/locale/lang/ja'; // element-ui lang

import enLocale from "./en";
import zhLocale from "./zh";
import esLocale from "./es";
import jaLocale from "./ja";
Vue.use(VueI18n);
var messages = {
  en: _objectSpread({}, enLocale, {}, elementEnLocale),
  zh: _objectSpread({}, zhLocale, {}, elementZhLocale),
  es: _objectSpread({}, esLocale, {}, elementEsLocale),
  ja: _objectSpread({}, jaLocale, {}, elementJaLocale)
};
export function getLanguage() {
  var chooseLanguage = Cookies.get('language');
  if (chooseLanguage) return chooseLanguage; // if has not choose language

  var language = (navigator.language || navigator.browserLanguage).toLowerCase();
  var locales = Object.keys(messages);

  for (var _i = 0, _locales = locales; _i < _locales.length; _i++) {
    var locale = _locales[_i];

    if (language.indexOf(locale) > -1) {
      return locale;
    }
  }

  return 'en';
}
var i18n = new VueI18n({
  // set locale
  // options: en | zh | es
  locale: getLanguage(),
  // set locale messages
  messages: messages
});
export default i18n;