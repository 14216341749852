import "core-js/modules/es7.symbol.async-iterator";
import "core-js/modules/es6.symbol";
import "core-js/modules/web.dom.iterable";
import "core-js/modules/es6.array.sort";
import Mock from 'mockjs';
var List = [];
var count = 100;
var baseContent = '<p>I am testing data, I am testing data.</p><p><img src="https://wpimg.wallstcn.com/4c69009c-0fd4-4153-b112-6cb53d1cf943"></p>';
var image_uri = 'https://wpimg.wallstcn.com/e4558086-631c-425c-9430-56ffb46e70b3';

for (var i = 0; i < count; i++) {
  List.push(Mock.mock({
    id: '@increment',
    timestamp: +Mock.Random.date('T'),
    author: '@first',
    reviewer: '@first',
    title: '@title(5, 10)',
    content_short: 'mock data',
    content: baseContent,
    forecast: '@float(0, 100, 2, 2)',
    importance: '@integer(1, 3)',
    'type|1': ['CN', 'US', 'JP', 'EU'],
    'status|1': ['published', 'draft', 'deleted'],
    display_time: '@datetime',
    comment_disabled: true,
    pageviews: '@integer(300, 5000)',
    image_uri: image_uri,
    platforms: ['a-platform']
  }));
}

export default [{
  url: '/article/list',
  type: 'get',
  response: function response(config) {
    var _config$query = config.query,
        importance = _config$query.importance,
        type = _config$query.type,
        title = _config$query.title,
        _config$query$page = _config$query.page,
        page = _config$query$page === void 0 ? 1 : _config$query$page,
        _config$query$limit = _config$query.limit,
        limit = _config$query$limit === void 0 ? 20 : _config$query$limit,
        sort = _config$query.sort;
    var mockList = List.filter(function (item) {
      if (importance && item.importance !== +importance) return false;
      if (type && item.type !== type) return false;
      if (title && item.title.indexOf(title) < 0) return false;
      return true;
    });

    if (sort === '-id') {
      mockList = mockList.reverse();
    }

    var pageList = mockList.filter(function (item, index) {
      return index < limit * page && index >= limit * (page - 1);
    });
    return {
      code: 20000,
      data: {
        total: mockList.length,
        items: pageList
      }
    };
  }
}, {
  url: '/article/detail',
  type: 'get',
  response: function response(config) {
    var id = config.query.id;
    var _iteratorNormalCompletion = true;
    var _didIteratorError = false;
    var _iteratorError = undefined;

    try {
      for (var _iterator = List[Symbol.iterator](), _step; !(_iteratorNormalCompletion = (_step = _iterator.next()).done); _iteratorNormalCompletion = true) {
        var article = _step.value;

        if (article.id === +id) {
          return {
            code: 20000,
            data: article
          };
        }
      }
    } catch (err) {
      _didIteratorError = true;
      _iteratorError = err;
    } finally {
      try {
        if (!_iteratorNormalCompletion && _iterator.return != null) {
          _iterator.return();
        }
      } finally {
        if (_didIteratorError) {
          throw _iteratorError;
        }
      }
    }
  }
}, {
  url: '/article/pv',
  type: 'get',
  response: function response(_) {
    return {
      code: 20000,
      data: {
        pvData: [{
          key: 'PC',
          pv: 1024
        }, {
          key: 'mobile',
          pv: 1024
        }, {
          key: 'ios',
          pv: 1024
        }, {
          key: 'android',
          pv: 1024
        }]
      }
    };
  }
}, {
  url: '/article/create',
  type: 'post',
  response: function response(_) {
    return {
      code: 20000,
      data: 'success'
    };
  }
}, {
  url: '/article/update',
  type: 'post',
  response: function response(_) {
    return {
      code: 20000,
      data: 'success'
    };
  }
}];