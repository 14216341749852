import "core-js/modules/es6.function.name";
import Mock from 'mockjs';
var NameList = [];
var count = 100;

for (var i = 0; i < count; i++) {
  NameList.push(Mock.mock({
    name: '@first'
  }));
}

NameList.push({
  name: 'mock-Pan'
});
export default [// username search
{
  url: '/search/user',
  type: 'get',
  response: function response(config) {
    var name = config.query.name;
    var mockNameList = NameList.filter(function (item) {
      var lowerCaseName = item.name.toLowerCase();
      return !(name && lowerCaseName.indexOf(name.toLowerCase()) < 0);
    });
    return {
      code: 20000,
      data: {
        items: mockNameList
      }
    };
  }
}, // transaction list
{
  url: '/transaction/list',
  type: 'get',
  response: function response(_) {
    return {
      code: 20000,
      data: {
        total: 20,
        'items|20': [{
          order_no: '@guid()',
          timestamp: +Mock.Random.date('T'),
          username: '@name()',
          price: '@float(1000, 15000, 0, 2)',
          'status|1': ['success', 'pending']
        }]
      }
    };
  }
}];